import React, { useState } from 'react';
import { Formik } from "formik";
import { Form, } from "react-bootstrap";
import * as yup from "yup";
import moment from "moment";
import error from "../../assets/error.svg";
import success from "../../assets/success.svg";
import {
  EtherScanLink,
  DefaultAmount,
  MinSubsequntReqMinutes,
  MaticExplorerLink,
} from '../config/constants';
import './style.scss';


const Web3 = require('web3');

const GEORLI_STRING = 'Sepolia Network';
const MATIC_STRING = 'Matic Network (Amoy Testnet)';
const chainTokenGeorliAddress = '0x85b01f05d92181b2dB8341a3f53342E809f2b7f7';
const chainTokenMumbaiMaticAddress = '0x3dc6052a693E4a2fc28Eb2Ea12fe0CfD3BD221D1';


const Success = ({ txHash, txHashLink }) => (

  <div className="text-center">
    <img
      src={success}
      className="status"
      alt="Success"
    />
    <h3 className="stake-heading mb-4">Success</h3>
    <p className="mx-5 mb-4">We have transfered {DefaultAmount} CHAIN to your address.</p>
    <p className="mx-5 mb-4">Please check Block Explorer to see if the transaction was successful.</p>
    <p className="mx-5 mb-4"><a className="card-link" href={txHashLink} target="_blank" rel="noopener noreferrer">{txHash}</a></p>
  </div>
);

const Failure = ({ errorMessage }) => (
  <div className="text-center">
    <img
      src={error}
      className="status my-2"
      alt="Error"
    />
    <h3 className="stake-heading mb-4">Error</h3>
    <p className="mx-5 mb-4">{errorMessage}</p>
  </div>
);

const AuthorizeForm = ({ onSubmit, onChange }) => {
  const schema = yup.object({
    address: yup
      .string()
      .typeError("Invalid Address")
      .required('Address is required'),
    network: yup
      .string()
      .required('Network is required')
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={{
        address: '',
        network: MATIC_STRING,
      }}
      isInitialValid={schema.isValidSync()}

    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="address" className="faucet-address-form-group">
            <Form.Control
              type="address"
              placeholder="Enter your testnet account address"
              name="address"
              value={values.address}
              onChange={(e) => {
                handleChange(e)
                onChange(e)
              }}
              isInvalid={!!errors.address}
            />
            <small className="form-text text-muted">
              We will transfer {DefaultAmount} CHAIN to your address.
            </small>
            <Form.Control.Feedback type="invalid">
              {errors.address}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="address" className="faucet-select-form-group">
            <Form.Label>Select Network</Form.Label>
            <Form.Control
              as="select"
              name="network"
              value={values.network}
              onChange={(e) => {
                handleChange(e)
                onChange(e)
              }}
              isInvalid={!!errors.network}
            >
              
              <option>{GEORLI_STRING}</option>
              <option>Matic Network (Amoy Testnet)</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select a network
            </Form.Control.Feedback>



          </Form.Group>
          <button className="theme-btn button" disabled={!isValid} type="submit" >
            SEND ME TEST CHAIN
          </button>
        </Form>
      )}
    </Formik>
  );
};

const SelectAddress = () => {
  const [isError, setIsError] = useState('')
  const [txnHash, setTxnHash] = useState('');
  const [txnHashLink, setTxnHashLink] = useState('');

  const onFormChange = () => {
    setTxnHash('')
    setIsError('')
  }
  const onSubmit = (values, { setErrors, resetForm }) => {
    console.log("🚀 ~ file: SelectAddress.js ~ line 129 ~ onSubmit ~ values", values)
    
    
    const isGeorliNetwork = values.network === GEORLI_STRING ? true : false;
    try {
      const currentTimeStamp = moment(new Date());
      const lastTimeStamp = localStorage?.getItem('faucetChainTokenLastTimestamp');
      if (lastTimeStamp) {
        const startTime = moment(lastTimeStamp)
        const duration = moment.duration(currentTimeStamp.diff(startTime));
        const minutes = duration.asMinutes();
        const retryMinutes = MinSubsequntReqMinutes - minutes
        if (minutes < MinSubsequntReqMinutes) {
          setErrors({
            address: `Please try after ${Math.ceil(retryMinutes)} minutes`
          })
          return
        }
      }
      localStorage.setItem('faucetChainTokenLastTimestamp', currentTimeStamp.toString());
      sendTestChainToken(values, isGeorliNetwork)
    }
    catch (error) {
      console.log("🚀 ~ file: SelectAddress.js ~ line 127 ~ SelectAddress ~ error", error)
      sendTestChainToken(values)
    }
  }

  const sendTestChainToken = async (toAddress, isGeorliNetwork) => {
    let ABI = [
      // transfer
      {
        "constant": false,
        "inputs": [
          {
            "name": "_to",
            "type": "address"
          },
          {
            "name": "_value",
            "type": "uint256"
          }
        ],
        "name": "transfer",
        "outputs": [
          {
            "name": "",
            "type": "bool"
          }
        ],
        "type": "function"
      }
    ];

    // const infuraProvider = isGeorliNetwork ?
    //   `https://sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
    //   :
    //   `https://polygon-amoy.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`

    const infuraProvider = isGeorliNetwork ?
      `https://ethereum-sepolia-rpc.publicnode.com`
      :
      `https://rpc-amoy.polygon.technology`
    
    const contractAddress = isGeorliNetwork ? chainTokenGeorliAddress : chainTokenMumbaiMaticAddress
    try {
      setTxnHash('');
      const web3 = new Web3(new Web3.providers.HttpProvider(infuraProvider));
      const chainID = await web3.eth.net.getId();
      // if (chainID !== NetworkChainID) {
      //   setIsError(`Please connect to ${process.env.REACT_APP_ETH_PROVIDER.toUpperCase()} Test Network, currently you're connected to some other network`);
      //   return;
      // }
      setIsError('');

      
      let decimals = web3.utils.toBN(18);
      let amount = web3.utils.toBN(DefaultAmount);
      let value = amount.mul(web3.utils.toBN(10).pow(decimals));;
      let contractInstance = new web3.eth.Contract(ABI, contractAddress);
      const byteCode = contractInstance.methods.transfer(toAddress.address, value).encodeABI();
      const account = web3.eth.accounts.privateKeyToAccount(process.env.REACT_APP_PRIV_KEY);
      web3.eth.getTransactionCount(account.address, function (err, nonce) {
        if (err) {
          setIsError(`Something went wrong! Please try again`)
          return;
        }
        const tx = {
          to: contractAddress,
          gas: web3.utils.toHex(100000),
          data: byteCode,
          nonce: web3.utils.toHex(nonce),
          chainId: isGeorliNetwork ? 11155111 : 80002
        }
        console.log("🚀 ~ tx:", tx)
        web3.eth.accounts.signTransaction(tx, account.privateKey, function (err, signedTx) {
          if (err) {
            //console.log('sign tx err', err);
            setIsError(`Error signing transaction! Please try again`)
            return;
          }
          setTxnHash(signedTx.transactionHash)
          setTxnHashLink(`${isGeorliNetwork ? EtherScanLink : MaticExplorerLink}${signedTx.transactionHash}`)
          web3.eth.sendSignedTransaction(signedTx.rawTransaction)
            .on('error', (error) => {
              console.log(error);
            });
        });
      });
    } catch (error) {
      setIsError(`Something went wrong! Please try again`)
    }
  }

  return (
    <div className="col-md-12 col-xl-10 mx-auto">
      <div className="card">
        <div className="card-body">
          <h4 className="stake-heading">Enter your testnet account address</h4>
          <AuthorizeForm onSubmit={onSubmit} onChange={onFormChange} />
        </div>
      </div>

      {
        txnHash !== '' || isError !== '' ?
          (<div className="card">
            <div className="card-body">
              {
                txnHash !== '' ? <Success txHashLink={txnHashLink} txHash={txnHash} /> : ''
              }
              {
                isError !== '' ? <Failure errorMessage={isError} /> : ''
              }
            </div>
          </div>)
          : ''
      }
    </div>
  );
};


export default SelectAddress;
