import React from 'react';
import Faucet from "./components/Faucet";
import Header from "./components/header"

function App() {
  return (
    <div className="App">
      <Header />
      <Faucet />
    </div>
  );
}

export default App;
