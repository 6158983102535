import React from 'react'
import logo from '../../assets/chain-logo-centered-full.png'
import './style.scss';
// import WalletSelector from '../WalletSelector/WalletSelector';

export default function Header() {
  let headerText = "";
  const ENV = process.env.REACT_APP_ETH_PROVIDER;
  switch (ENV) {
    case "ropsten":
      headerText = "Ropsten Testnet";
      break;
    case "kovan":
      headerText = "Kovan Testnet";
      break;
    case "live":
      headerText = "Main Ethereum";
      break;
    case "goerli":
      headerText ="Goerli Testnet";
      break;
  

    default: headerText = "Development Ethereum";
  }
  return (
    <div>
      <header className="App-header">
        <div className="container row">
          <div className="logo col ">
            <a
              className="App-link"
              href="/"
            >
              <img src={logo} className="App-logo" alt="logo" />
            </a>
          </div>
          {/* <p className="col network"  style={{fontColor:"#ffffff"}}>{headerText}</p> */}
        </div>
        
      </header>
    </div>
  )
}
