import React, { Component } from "react";
import SelectAddress from "./SelectAddress";


export default class Stake extends Component {
  render() {
    return (
      <div className="col-md-12 col-xl-10" style={{margin:"0 auto"}}>
        <SelectAddress/>
      </div>
    );
  }
}
